import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Select } from "antd";
import {
   PlusOutlined,
   EditOutlined,
   DeleteOutlined
} from '@ant-design/icons';
import ModalParameter from "components/modal/Parameter";
import axios from "axios";
import { MASTER_DATA_URL } from "services/config";
import { ReactComponent as Confirmation } from "../../../assets/logo/Confirmation.svg"

export const IndicatorForm = ({ Form, form, disable, name, priorityProgramIndex, outcomeIndex, outputIndex }) => {
   const [listParameter, setListParameter] = useState([]);
   const [listIndicator, setListIndicator] = useState([]);
   const loadListIndicator = () => {
      axios.get(`${MASTER_DATA_URL}/Indicator/list`)
         .then(res => {
            if (res.data) {
               let arr = [];

               res.data.data?.forEach(item => {
                  item.indicator.forEach(indicator => {
                     arr.push({
                        ...item,
                        key: indicator.id,
                        value: indicator.nama,
                        label: indicator.nama
                     })
                  })
               })

               setListIndicator(arr);
            }
         })
         .catch(err => {
            console.log(err)
         })
   }

   useEffect(() => {
      loadListIndicator();
   }, [])

   const checkParameterValue = (indicatorIndex, parameterIndex, key) => {
      let obj = form.getFieldsValue([name]);
      let priorityPrograms = obj[name];

      let parameter;
      parameter = priorityPrograms[priorityProgramIndex].outcomes[outcomeIndex].outputs[outputIndex].indicators[indicatorIndex].parameters[parameterIndex];

      if (parameter) {
         if (key === "all") {
            return parameter;
         } else {
            return parameter[key];
         }
      } else {
         return undefined;
      }
   }

   const setParameterValue = (indicatorIndex, parameterIndex, key, value) => {
      let parameter = checkParameterValue(indicatorIndex, parameterIndex, "all");
      parameter[key] = value;

      form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "outputs", outputIndex, "indicators", indicatorIndex, "parameters", parameterIndex], parameter)
   }

   const handleDeleteIndicator = (remove, idx) => {
		Modal.confirm({
			centered: true,
			title: '',
			icon: <Confirmation />,
			okText: "Confirm",
			bodyStyle: { justifyContent: "center" },
			content: (
				<>
					<h1 className="mb-4" style={{ fontSize: "17px", fontWeight: "600", lineHeight: "24px", justifyContent: "center" }}>
						Anda yakin menghapus Indicator ini?
					</h1>
				</>
			),
			onOk() {
				remove(idx)
			}
		})
	}

   return (
      <Form.List name={[outputIndex, "indicators"]}>
         {(indicators, { add, remove }) => (
            <>
               {indicators.map((indicator, indicatorIndex) => {
                  return (
                     <>
                        <div style={{ background: "#F9FAFB" }} className="mx-4 px-4 pt-4">
                           <Row gutter={12} >
                              <Col span={24}>
                                 <div className="">
                                    <label className="font-medium">Indicator</label> &nbsp;
                                    <span><DeleteOutlined onClick={() => {handleDeleteIndicator(remove, indicatorIndex)}}/></span>
                                    <Form.Item
                                       name={[indicatorIndex, 'indicatorName']}
                                    >
                                       <Select
                                          placeholder="Indicator"
                                          type="text"
                                          className="mt-2"
                                          disabled={disable}
                                          options={listIndicator}
                                          onChange={(value, option) => {
                                             const fieldGroupName = name;
                                             const fieldGroup = form.getFieldsValue([fieldGroupName]);

                                             const priorityPrograms = fieldGroup[fieldGroupName];

                                             indicator = priorityPrograms[priorityProgramIndex].outcomes[outcomeIndex].outputs[outputIndex].indicators[indicatorIndex];
                                             const indicatorParameter = option.indicator.find(item => value.includes(item.nama))?.parameter ?? [];

                                             indicator = {
                                                ...indicator,
                                                originalId: option.id,
                                                indicatorName: value,
                                                parameter: indicatorParameter
                                             }

                                             form.setFieldValue([fieldGroupName, priorityProgramIndex, "outcomes", outcomeIndex, "outputs", outputIndex, "indicators", indicatorIndex], indicator)
                                          }}
                                       />
                                    </Form.Item>
                                 </div>
                              </Col>
                              {/* {!disable &&
                                 <Col span={4}>
                                    <Button
                                       icon={<PlusOutlined />}
                                       className="btn-primary mt-8 w-full"
                                       disabled={disable}
                                       onClick={() => {
                                          let obj = form.getFieldsValue([name]);
                                          let priorityPrograms = obj[name];

                                          let indicators;
                                          indicators = priorityPrograms[priorityProgramIndex].outcomes[outcomeIndex].outputs[outputIndex].indicators;

                                          if (indicators[indicatorIndex]) {
                                             if (indicators[indicatorIndex].parameters) {
                                                indicators[indicatorIndex].parameters.push({
                                                   originalId: "3545536d-db8d-46dd-a9e5-339818742980", //TEMP
                                                   parameterName: "Default Parameter",
                                                   target: 100,
                                                   unit: "Unit",
                                                   indicativeActivities: [
                                                      {
                                                         originalId: "3545536d-db8d-46dd-a9e5-339818742980", //TEMP
                                                         indicativeActivityName: "Default Activity"
                                                      }
                                                   ],
                                                   _indicativeActivities: ["Default Activity"]
                                                })
                                             } else {
                                                indicators[indicatorIndex] = {
                                                   ...indicators[indicatorIndex],
                                                   parameters: [{
                                                      originalId: "3545536d-db8d-46dd-a9e5-339818742980", //TEMP
                                                      parameterName: "Default Parameter",
                                                      target: 100,
                                                      unit: "Unit",
                                                      indicativeActivities: [
                                                         {
                                                            originalId: "3545536d-db8d-46dd-a9e5-339818742980", //TEMP
                                                            indicativeActivityName: "Default Activity"
                                                         }
                                                      ],
                                                      _indicativeActivities: ["Default Activity"]
                                                   }]
                                                }
                                             }
                                          } else {
                                             indicators[indicatorIndex] = {
                                                ...indicators[indicatorIndex],
                                                parameters: [{
                                                   originalId: "3545536d-db8d-46dd-a9e5-339818742980", //TEMP
                                                   parameterName: "Default Parameter",
                                                   target: 100,
                                                   unit: "Unit",
                                                   indicativeActivities: [
                                                      {
                                                         originalId: "3545536d-db8d-46dd-a9e5-339818742980", //TEMP
                                                         indicativeActivityName: "Default Activity"
                                                      }
                                                   ],
                                                   _indicativeActivities: ["Default Activity"]
                                                }]
                                             }
                                          }

                                          form.setFieldValue([name, priorityProgramIndex, "outcomes", outcomeIndex, "outputs", outputIndex, "indicators"], indicators)


                                          let parameterIndex = indicators[indicatorIndex].parameters ? indicators[indicatorIndex].parameters.length - 1 : 0;
                                          setListParameter(indicators[indicatorIndex].listParameter)

                                          setParameterValue(indicatorIndex, parameterIndex, "showModal", true);
                                          setParameterValue(indicatorIndex, parameterIndex, "modalTitle", "Tambah");
                                       }}
                                    >
                                       Parameter
                                    </Button>
                                 </Col>
                              } */}
                           </Row>
                        </div>

                        <Form.List name={[indicatorIndex, "parameters"]}>
                           {(parameters, { add, remove }) => (
                              <>
                                 {parameters.map((parameter, parameterIndex) => {
                                    return (
                                       <div className="mx-4 px-4 pb-4" style={{ background: "#F9FAFB" }}>
                                          <ModalParameter
                                             open={checkParameterValue(indicatorIndex, parameterIndex, "showModal")}
                                             handleCancel={() => setParameterValue(indicatorIndex, parameterIndex, "showModal", false)}
                                             handleOk={() => setParameterValue(indicatorIndex, parameterIndex, "showModal", false)}
                                             form={form}
                                             name={name}
                                             priorityProgramIndex={priorityProgramIndex}
                                             outcomeIndex={outcomeIndex}
                                             outputIndex={outputIndex}
                                             indicatorIndex={indicatorIndex}
                                             parameterIndex={parameterIndex}
                                             parameterValue={checkParameterValue(indicatorIndex, parameterIndex, "all")}
                                             listParameter={listParameter}
                                             remove={remove}
                                          />
                                          <Row className="flex px-4 py-4" span={24} style={{ background: "#FFFF" }}>
                                             <Col className="font-medium mr-2">{parameterIndex + 1})</Col>
                                             <Col span={20}>
                                                <Row className="flex">
                                                   <Col span={3} className="font-medium">Parameter</Col>
                                                   <Col className="mr-2">:</Col>
                                                   <Col span={18}>{checkParameterValue(indicatorIndex, parameterIndex, "parameterName")}</Col>
                                                </Row>
                                                <Row className="flex">
                                                   <Col span={3} className="font-medium">Target</Col>
                                                   <Col className="mr-2">:</Col>
                                                   <Col span={18}>
                                                      {checkParameterValue(indicatorIndex, parameterIndex, "target")} {" "}
                                                      ({checkParameterValue(indicatorIndex, parameterIndex, "unit")})
                                                   </Col>
                                                </Row>
                                                <Row className="flex">
                                                   <Col span={3} className="font-medium">Indicative Activity</Col>
                                                   <Col className="mr-2">:</Col>
                                                   <Col span={18}>
                                                      {
                                                         checkParameterValue(indicatorIndex, parameterIndex, "indicativeActivities") &&
                                                         checkParameterValue(indicatorIndex, parameterIndex, "indicativeActivities").map(item => (
                                                            <span>◉ {item?.indicativeActivityName} <br /></span>
                                                         ))
                                                      }
                                                   </Col>
                                                </Row>
                                             </Col>
                                             <Col className="flex justify-end items-center" span={3}>
                                                {!disable &&
                                                   <>
                                                      <Button
                                                         type="ghost"
                                                         icon={<EditOutlined style={{ fontSize: 20 }} />}
                                                         disabled={disable}
                                                         onClick={() => {
                                                            setParameterValue(indicatorIndex, parameterIndex, "showModal", true);
                                                            setParameterValue(indicatorIndex, parameterIndex, "modalTitle", "Edit");
                                                         }}
                                                      />
                                                      <Button
                                                         type="ghost"
                                                         icon={<DeleteOutlined style={{ fontSize: 20, color: "#FF0000" }} />}
                                                         disabled={disable}
                                                         onClick={() => {
                                                            remove(parameterIndex);
                                                            console.log(form.getFieldsValue())
                                                         }}
                                                      />
                                                   </>
                                                }
                                             </Col>
                                          </Row>
                                       </div>

                                    )
                                 })}
                              </>
                           )}
                        </Form.List>
                     </>
                  )
               })}
            </>
         )
         }
      </Form.List>
   )
}